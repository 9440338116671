
import { computed, defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  name: 'CardTag',
  components: { InlineSvg },
  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const newName = computed(() => {
      const value = props.name;

      return value[0].toUpperCase() + value.slice(1);
    });

    const icons = [
      'book',
      'brain',
      'cards',
      'flower',
      'heart',
      'measuring',
      'mind',
      'rays',
      'star',
    ];

    function randomIcon() {
      const iconsLength = icons.length;
      const randomInt = Math.floor(Math.random() * iconsLength);

      return icons[randomInt];
    }

    return {
      newName,

      randomIcon,
    };
  },
});
