import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-tag" }
const _hoisted_2 = { class: "card-tag__icon" }
const _hoisted_3 = { class: "card-tag__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_inline_svg, {
        src: require(`@/assets/svg/${_ctx.randomIcon()}.svg`)
      }, null, 8, ["src"])
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.newName), 1)
  ]))
}