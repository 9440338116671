import {
  createRouter, RouteRecordRaw, createWebHistory,
} from 'vue-router';

import HomeGroup from '@/views/HomeGroup.vue';
import CardGroup from '@/views/CardGroup.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeGroup,
  },
  {
    path: '/card/:id',
    name: 'card',
    component: CardGroup,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
