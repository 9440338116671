
import { defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { TouchRipple } from 'vue-touch-ripple';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'BtnWhite',
  components: { TouchRipple, InlineSvg },
  props: {
    isShare: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const router = useRouter();

    const onClick = () => {
      router.replace('/');
    };

    return {
      onClick,
    };
  },
});
