
import {
  computed,
  defineComponent, nextTick,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import SplitType from 'split-type';
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';
import { store } from '@/store';
import { TouchRipple } from 'vue-touch-ripple';

import InlineSvg from 'vue-inline-svg';
import { TarotMotion } from '@/modules/Tarot';
import { isTablet } from '@/modules/Tarot/utils';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import RabbitsStore from '@/modules/Tarot/RabbitsStore';

export default defineComponent({
  name: 'SpreadView',
  components: { InlineSvg, TouchRipple },
  setup() {
    const isCoverHeader = ref(false);
    const paddingTop = ref('unset');
    const hero: Ref<HTMLElement | null> = ref(null);
    const isBlockBtn = ref(false);
    const title: Ref<HTMLElement | null> = ref(null);
    const img: Ref<HTMLElement | null> = ref(null);
    const subtitle: Ref<HTMLElement | null> = ref(null);
    const isSvgAnimation = ref(false);
    const star = ref(null);
    const isLoading = computed(() => store.isLoading);
    const mainBtn: Ref<HTMLElement | null> = ref(null);
    const router = useRouter();

    const debouncedOnResizeWindow = debounce(onResizeWindow, 200);

    let subtitleSplitType: any = null;
    let timeline = gsap.timeline();

    watch(() => isLoading.value, (value) => {
      if (!value) {
        setAnimation();
      }
    });

    function setInitialElement() {
      isCoverHeader.value = false;
      paddingTop.value = 'unset';
    }

    function checkCoverHeader() {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { y }: any = hero.value?.getBoundingClientRect();
      const header = document.querySelector('header');
      let headerHeight = 0;

      if (header) {
        headerHeight = Number(window.getComputedStyle(header).height.replace('px', ''));
      }

      if (y < headerHeight || y <= 0) {
        isCoverHeader.value = true;
        paddingTop.value = `calc(${headerHeight}px + 2rem)`;
      } else {
        isCoverHeader.value = false;
      }
    }

    function onResizeWindow() {
      setInitialElement();

      nextTick(() => {
        checkCoverHeader();
      });

      if (isLoading.value) {
        return;
      }

      subtitleSplitType?.split();

      const element = subtitle.value;
      const words = element ? element.querySelectorAll('.word') : [];

      gsap.set(words, { opacity: 1, y: 0 });
    }

    async function setAnimation() {
      timeline.kill();

      const subtitleElement = subtitle.value as HTMLElement;
      const titleElement = title.value as HTMLElement;

      const subtitleWords = subtitleElement.querySelectorAll('.word');
      const spanTitles = titleElement.querySelectorAll('span');

      const { detailMode } = TarotMotion.cards;

      // if (detailMode) await TarotMotion.cards.backToChoice();

      timeline = gsap.timeline({
        delay: detailMode ? 0 : 0.2,
        onStart() {
          gsap.to(star.value, {
            scale: 1, ease: 'power4.out', duration: 1, willChange: 'transform',
          });

          if (!detailMode) {
            TarotMotion.cards.show();
          }

          TarotMotion.cards.once('click', clickHandler);
        },
        onUpdate() {
          const progress = this.progress();

          if (progress * 10 > 4) {
            isSvgAnimation.value = true;
          }
        },
      });

      timeline
        .to(spanTitles, {
          opacity: 1, y: 0, stagger: 0.15, ease: 'sine.inOut',
        })
        .to(subtitleWords, {
          opacity: 1, rotate: 0, y: 0, ease: 'sine.inOut', stagger: 0.05,
        }, '>-0.7')
        .fromTo(mainBtn.value, { pointerEvents: 'none' }, { opacity: 1, ease: 'sine.out' }).set(mainBtn.value, { clearProps: 'pointerEvents' });
    }

    onBeforeRouteLeave(async (from, to, next) => {
      await beforeLeave();
      next();
    });

    const beforeLeave = () => new Promise((resolve) => {
      timeline?.kill();

      const element = subtitle.value;
      const lines = element ? element.querySelectorAll('.line') : [];

      const animation = async () => {
        timeline = gsap.timeline({
          onComplete: isTablet() ? undefined : resolve,
        }).timeScale(1.5);

        timeline
          .to(star, { opacity: 0 })
          .to(lines, {
            opacity: 0, y: -20, stagger: 0.1, delay: 0.05, willChange: 'transform, opacity',
          }, '<')
          .to(title.value, {
            opacity: 0, y: -20, stagger: 0.1, delay: 0.1, willChange: 'transform, opacity',
          }, '<')
          .to(mainBtn.value, { opacity: 0 }, '<');

        if (isTablet()) {
          await TarotMotion.cards.toDetail(false);

          resolve({});
        } else {
          TarotMotion.cards.toDetail(false);
        }
      };

      animation();
    });

    const clickHandler = async () => {
      await router.replace(`/card/${RabbitsStore.selection}`);
      RabbitsStore.update();
    };

    onMounted(() => {
      store.setBlockBtn(false);

      window.addEventListener('resize', debouncedOnResizeWindow);

      setInitialElement();

      new Promise<void>((resolve) => {
        setTimeout(() => {
          checkCoverHeader();

          if (subtitle.value) {
            subtitleSplitType = new SplitType(
              subtitle.value,
              { types: 'words, lines' },
            );
          }

          gsap.set(subtitle.value, { opacity: 1 });

          resolve();
        }, 100);
      }).then(() => {
        if (!isLoading.value) {
          store.setView('card', 'card');
          setAnimation();
        }
      });
    });

    onBeforeUnmount(() => {
      timeline?.kill();

      TarotMotion.cards?.removeListener('click', clickHandler);
      window.removeEventListener('resize', debouncedOnResizeWindow);
    });

    onBeforeRouteLeave(() => store.setBlockBtn(true));

    return {
      isBlockBtn,
      paddingTop,
      hero,
      isCoverHeader,
      subtitle,
      isSvgAnimation,
      star,
      title,
      img,
      mainBtn,
      isLoading,
      randomCard: async () => {
        if (!TarotMotion.cards.allowChange) return;
        store.setBlockBtn(true);

        isBlockBtn.value = true;

        await TarotMotion.cards.random();
        TarotMotion.cards.onClick();
      },

    };
  },
});
