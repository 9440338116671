<template>
  <CardView v-if="store.cardView === 'card'"></CardView>
  <DivinationView v-if="store.cardView === 'divination'"></DivinationView>
</template>

<script>
import { store } from '@/store';
import DivinationView from '@/views/DivinationView.vue';
import CardView from '@/views/CardView.vue';

export default {
  name: 'HomeGroup',
  components: { DivinationView, CardView },

  setup() {
    return {
      store,
    };
  },
};
</script>
