import * as events from 'events';
import { gsap } from 'gsap';

declare interface AnimatedValue {
  on(event: 'finish', listener: () => void): this;
  on(event: 'update', listener: (value: number) => void): this;
}

type AnimatedValueOptions = {
  from: number,
  to: number
  duration: number,
  ease: string
}
class AnimatedValue extends events.EventEmitter {
  options: AnimatedValueOptions

  value = 0

  animated = {
    value: 0,
  }

  tl: gsap.core.Tween | null = null

  constructor(from = 0, to = 100, duration = 4, ease = 'power1.inOut') {
    super();

    this.options = {
      from,
      to,
      duration,
      ease,
    };

    this.animated.value = this.options.from;
  }

  to(value = 100) {
    this.value = value;

    const { to, duration, ease } = this.options;
    this.tl?.kill();

    let diff = value - this.animated.value;
    diff = diff < 0 ? 0 : diff;
    const $duration = (duration / to) * diff;

    this.tl = gsap.to(this.animated, {
      value,
      duration: $duration,
      ease,
      onUpdate: () => {
        this.emit('update', this.animated.value);

        if (this.animated.value >= to) {
          this.emit('finish');
        }
      },
    });
  }
}

export default AnimatedValue;
