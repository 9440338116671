import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__wrapper" }
const _hoisted_2 = { class: "header__logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("header", {
    id: "header",
    class: _normalizeClass(["header", { '_disabled': _ctx.isBlockBtn }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "/",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_inline_svg, {
            class: _normalizeClass(["svg-logo", { 'active': _ctx.isSvgAnimation }]),
            src: require('@/assets/svg/logo.svg'),
            name: "logo"
          }, null, 8, ["class", "src"])
        ])
      ])
    ])
  ], 2))
}