<template>
  <HomeView v-if="store.homeView === 'home'"></HomeView>
  <SpreadView v-else></SpreadView>
</template>

<script>
import { store } from '@/store';
import HomeView from '@/views/HomeView.vue';
import SpreadView from '@/views/SpreadView.vue';

export default {
  name: 'HomeGroup',
  components: {
    HomeView,
    SpreadView,
  },

  setup() {
    return {
      store,
    };
  },
};
</script>
