
import { computed, defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { store } from '@/store';
import { TarotMotion } from '@/modules/Tarot';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AppHeader',
  components: { InlineSvg },
  setup() {
    const router = useRouter();
    const isBlockBtn = computed(() => store.isBlockBtn);

    const isSvgAnimation = computed(() => !store.isLoading);

    const onClick = () => {
      if (TarotMotion.cards.allowChange || TarotMotion.cards.detailMode) {
        router.replace('/');
      }
    };

    return {
      isBlockBtn,
      isSvgAnimation,
      onClick,
    };
  },
});
